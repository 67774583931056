import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Layout from "../components/Layout"
import { Swiper, SwiperSlide } from "swiper/react"
import Marquee from "react-fast-marquee"

import styled, { createGlobalStyle } from "styled-components"
import { Hero, HeroContent, HeroHeading, HeroButton } from "../components/Hero"
import { LinkCta } from "../components/Button"
import BackgroundImage from "gatsby-background-image"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import DivHelper from "../components/DivHelper"

SwiperCore.use([Navigation, Pagination, Autoplay])

const BlockWrapper = styled.div`
  padding: 40px 0;
`

const BlackBackground = styled(BlockWrapper)`
  background: #000;
  color: #fff;
`

const SectionHeading = styled.h2`
  position: relative;
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 200;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    content: "";
    left: 50%;
    width: 50%;
    height: 1px;
    bottom: -20px;
    transform: translateX(-50%);
    background: ${props => (props.white ? "#fff" : "#000")};
  }

  @media (min-width: 576px) {
    font-size: 1.75rem;
  }

  @media (min-width: 768px) {
    font-size: 2;
  }

  @media (min-width: 992px) {
    font-size: 2.25rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.5rem;
  }
`

const SubHeading = styled.h3`
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 500;

  @media (min-width: 576px) {
    font-size: 1.55rem;
  }

  @media (min-width: 768px) {
    font-size: 1.8rem;
  }
`

const SwiperStyles = createGlobalStyle`
.swiper-slide {
  cursor: grab;
}

  .swiper-button-prev, .swiper-button-next {
    color: #fff;
  }

  .swiper-pagination-bullet, .swiper-pagination-bullet-active {
    background: #fff;
  }

  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 50px;
    border-radius: 2px;
    margin: 0 10px;
  }
`

const Background = ({ image, className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        lube: file(relativePath: { eq: "stock/romantic-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gummies: file(relativePath: { eq: "stock/color.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        oil: file(relativePath: { eq: "stock/oil-2.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data[image].childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const RowMargin = styled(Row)`
  margin-top: 40px;
`

const StyledMarquee = styled(Marquee)`
  background: #000;
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
`

const MarqueeContent = styled.div`
  padding: 10px 0;
`

export default function Home() {
  return (
    <Layout light mt0 mb0>
      <SwiperStyles />
      <Hero height={50}>
        <Swiper
          slidesPerView={1}
          loop={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          style={{ height: "100%" }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={800}
        >
          <SwiperSlide style={{ height: "100%" }}>
            <Background image="lube">
              <HeroContent pt={50}>
                <Container>
                  <Row>
                    <Col align="center">
                      <HeroHeading>CBD Gleitgel</HeroHeading>
                      <HeroButton to="/vergleiche/cbd-gleitgel/" white>
                        Zum CBD Gleitgel Vergleich
                      </HeroButton>
                    </Col>
                  </Row>
                </Container>
              </HeroContent>
            </Background>
          </SwiperSlide>
          <SwiperSlide style={{ height: "100%" }}>
            <Background image="gummies">
              <HeroContent pt={50}>
                <Container>
                  <Row>
                    <Col align="center">
                      <HeroHeading>CBD Fruchtgummis</HeroHeading>
                      <HeroButton to="/vergleiche/cbd-fruchtgummis/" white>
                        Zum CBD Fruchtgummis Vergleich
                      </HeroButton>
                    </Col>
                  </Row>
                </Container>
              </HeroContent>
            </Background>
          </SwiperSlide>
          <SwiperSlide style={{ height: "100%" }}>
            <Background image="oil">
              <HeroContent pt={50}>
                <Container>
                  <Row>
                    <Col align="center">
                      <HeroHeading>CBD Öl</HeroHeading>
                      <HeroButton to="/vergleiche/cbd-oel/" white>
                        Zum CBD Öl
                        <br />
                        Vergleich
                      </HeroButton>
                    </Col>
                  </Row>
                </Container>
              </HeroContent>
            </Background>
          </SwiperSlide>
        </Swiper>
      </Hero>
      <StyledMarquee speed={80} gradient={false}>
        <MarqueeContent>
          CBD Gleitgel Test jetzt verfügbar&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </MarqueeContent>
        <MarqueeContent>
          CBD Gleitgel Test jetzt verfügbar&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </MarqueeContent>
        <MarqueeContent>
          CBD Gleitgel Test jetzt verfügbar&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </MarqueeContent>
        <MarqueeContent>
          CBD Gleitgel Test jetzt verfügbar&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </MarqueeContent>
        <MarqueeContent>
          CBD Gleitgel Test jetzt verfügbar&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        </MarqueeContent>
      </StyledMarquee>
      <BlockWrapper>
        <Container>
          <Row>
            <Col>
              <DivHelper center>
                <SectionHeading>Ratgeber Highlights</SectionHeading>
              </DivHelper>
            </Col>
          </Row>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>
                CBD Gleitgel: Das Upgrade für Ihr Liebesleben
              </SubHeading>
              <p>
                In diesem Artikel erfahren Sie alles über den neuesten Trend der{" "}
                <strong>CBD Gleitgele</strong>. Wir erklären, welche Wirkung die
                neuartigen Gleitmittel versprechen und für wen sie sich lohnen.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-gleitgel/">
                  Zum CBD Gleitgel Artikel
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>Sarah's Blessing Fruchtgummis</SubHeading>
              <p>
                <strong>Sarah's Blessing Fruchtgummis</strong> sind in aller
                Munde – unser kritischer Test bringt Klarheit.
              </p>
              <div>
                <LinkCta to="/ratgeber/sarahs-blessing-fruchtgummis/">
                  Zum Test
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>
                CBD kaufen ist legal – das sagen EuGH und BGH dazu
              </SubHeading>
              <p>
                Hier erfahren Sie, begründet durch{" "}
                <strong>aktuelle Gerichtsurteile</strong>, wieso{" "}
                <strong>CBD in Deutschland legal</strong> ist und Sie somit
                unbesorgt entsprechende CBD Produkte kaufen und konsumieren
                können.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-legalitaet/">
                  Zum Artikel über die Legalität von CBD
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>THC Grenzwert Erhöhung</SubHeading>
              <p>
                Im Februar 2023 passt Deutschland die Vorgaben zum THC Grenzwert
                an und <strong>erhöht diesen von 0,2% auf 0,3%</strong>. In
                unserem Artikel erfahren Sie alle Details zu der Änderung und
                was diese für Sie bedeutet.
              </p>
              <div>
                <LinkCta to="/ratgeber/thc-gehalt-erhoehung/">
                  Mehr Informationen zur Erhöhung
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>CBD Öl Test</SubHeading>
              <p>
                In unserem CBD Öl Selbsttest erhalten Sie nach dem Beantworten
                nur weniger Fragen eine{" "}
                <strong>persönliche Empfehlung für CBD Öl Produkte</strong>, die
                am besten zu Ihnen passen.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-oel-test/">Zum Selbsttest</LinkCta>
              </div>
            </Col>
          </RowMargin>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>
                Vor- und Nachteile der zwei häufigsten CBD Produkte
              </SubHeading>
              <p>
                In unserem Ratgeber geben wir Ihnen einen Überblick{" "}
                <strong>zu den zwei häufigsten CBD Produkten</strong> und
                stellen die wichtigsten <strong>Vorteile und Nachteile</strong>{" "}
                gegenüber.
              </p>
              <div>
                <LinkCta to="/ratgeber/produkt-ratgeber/">
                  Zum Produkt Ratgeber
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
        </Container>
      </BlockWrapper>
      <BlackBackground>
        <Container>
          <Row>
            <Col>
              <DivHelper center>
                <SectionHeading white>Über Uns</SectionHeading>
              </DivHelper>
            </Col>
          </Row>
          <RowMargin justify="center">
            <Col md={6}>
              <SubHeading>Forschungsmethode</SubHeading>
              <p>
                Getreu unseren Prinzipien objektiv, transparent und ehrlich
                können Sie hier nachlesen, wie wir unsere CBD Produktttests
                durchgeführt haben.
              </p>
              <div>
                <LinkCta to="/forschungsmethode/" white>
                  Infos zur Forschungsmethode
                </LinkCta>
              </div>
            </Col>
          </RowMargin>
        </Container>
      </BlackBackground>
    </Layout>
  )
}
